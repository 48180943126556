import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';

// Components
// =============================================================================
import Box from 'common/src/components/Box';
import Text from 'common/src/components/Text';
import Heading from 'common/src/components/Heading';
import Image from 'common/src/components/Image';
import Button from 'common/src/components/Button';

// Images
// =============================================================================
import ErrorImage from 'common/src/assets/image/404.png';

import { ErrorWrapper, ErrorConatent, ButtonWrapper } from './error.style';

// Colours
// =============================================================================
//import brand from 'common/src/theme/landing/brand';

const ErrorSec = ({ imageWrapper, title, text, homeButton }) => {
  return (
    <ErrorWrapper>
      <ErrorConatent>
        <Box {...imageWrapper} className="image-wrapper">
          <Image src={ErrorImage} alt="404" />
        </Box>
        <Heading {...title} content="PAGE NOT FOUND" />
        <Text
          {...text}
          content="Looks like the page you're trying to visit dosen't exist. Please check the URL and try your luck again."
        />
        <ButtonWrapper>
          <Link to="/">
            <Button {...homeButton} title="Discover Cybertonica" />
          </Link>
        </ButtonWrapper>
      </ErrorConatent>
    </ErrorWrapper>
  );
};

ErrorSec.propTypes = {
  imageWrapper: PropTypes.object,
};

ErrorSec.defaultProps = {
  imageWrapper: {
    mb: ['40px', '55px'],
  },
  title: {
    fontSize: ['26px', '32px', '38px', '48px'],
    color: '#fff',
    letterSpacing: '-0.025em',
    mb: ['20px', '25px', '25px', '25px', '35px'],
    lineHeight: '1.31',
    textAlign: 'center',
    fontWeight: '500',
  },
  text: {
    fontSize: ['15px', '16px', '16px', '18px', '18px'],
    color: '#fff',
    lineHeight: '1.6',
    mb: ['30px', '40px', '50px', '60px'],
    textAlign: 'center',
  },
  homeButton: {
    type: 'button',
    fontSize: '18px',
    fontWeight: '500',
    colors: 'cyber5WithBg',
    pl: ['15px', '22px'],
    pr: ['15px', '22px'],
    iconPosition: 'left',
    //bg: '#e2e7f0',
  },
};

export default ErrorSec;
