import React from 'react';
import SEO from '../components/seo';
import ErrorSec from '../containers/Error';
import { ThemeProvider } from 'styled-components';
import { landingTheme } from 'common/src/theme/landing';
import { ResetCSS } from 'common/src/assets/css/style';

const NotFoundPage = () => (
  <ThemeProvider theme={landingTheme}>
    <SEO title="404: Not found" />
    <ResetCSS />
    <ErrorSec></ErrorSec>
  </ThemeProvider>
);

export default NotFoundPage;
